import Ads from "./Ads";

export const AdsPageConfig={
    routes: [
      {
        path: "/ads",
        exact: true,
        component: Ads,
      },
    ]
}
