import ProductsReportPage from "./salesProductReport";

export const SalesProductReoprtPageConfig = {
  routes: [
    {
      path: "/salesProductReport",
      exact: true,
      component: ProductsReportPage,
    },
  ],
};
