import SalesPageLogin from "./salesLogin";

export const SalesLoginConfig = {
  routes: [
    {
      path: "/salesLogin",
      exact: true,
      component: SalesPageLogin
    }
  ]
};
