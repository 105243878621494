import React from "react";
import fetch from "isomorphic-fetch";
import { url } from "../../../utiles/config";

export const adminForgetPassword = () => {
  return fetch(`${url}admin-forgotPassword`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => err);
};
