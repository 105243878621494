import HomePage from "./homePage";

export const HomePageCofig = {
  routes: [
    {
      path: "/salesDashboard",
      exact: true,
      component: HomePage
    }
  ]
};
