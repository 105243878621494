import ProductsReportPage from "./ProductReports";

export const ProductReoprtPageConfig = {
  routes: [
    {
      path: "/productReport",
      exact: true,
      component: ProductsReportPage,
    },
  ],
};
