import Error404Page from "./Error404Page";

export const Error404PageConfig = {
  routes: [
    {
      path: "/pages/errors/error-404",
      exact: true,
      component: Error404Page
    }
  ]
};










{/* <div className="container">
  <Card>
    <div className="logo-img">
      <img
        src={Logo}
        // style={{ marginTop: "20px", maxHeight: "50%", maxWidth: "20%" }}
        className="img"
      />
    </div>
    <CardContent>
      <div className="texts">
        <Typography variant="h5" style={{ color: "#203040" }}>
          Login
        </Typography>
        Signin to your account
      </div>
      <Collapse in={openAlert}>
        <Alert
          severity={openAlertCon}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {openAlerMess}
        </Alert>
      </Collapse>
      <form onSubmit={validate}>
        <TextField
          InputLabelProps={{
            style: { color: "#203040" },
          }}
          fullWidth
          className={classes.borderTextField}
          label="Email or UserName"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon style={{ color: "#203040" }} color="primary" />
              </InputAdornment>
            ),
          }}
          value={user_identifier}
          onChange={(e) => {
            dispatch(loginActions.setUser_Identifier(e.target.value));
          }}
          helperText={user_IdentifierErr}
        />
        <TextField
          fullWidth
          className={classes.borderTextField}
          style={{ marginTop: "20px" }}
          required
          id="outlined-required"
          label="Password"
          InputLabelProps={{
            style: { color: "#203040" },
          }}
          type={showPassword ? "text" : "password"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon style={{ color: "#203040" }}></LockIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={password}
          onChange={(e) => {
            dispatch(loginActions.setPassword(e.target.value));
          }}
          helperText={passwordErr}
        />
        <CardActions style={{ marginTop: "8%" }}>
          <Button
            type="submit"
            style={{ backgroundColor: "#203040", color: "white" }}
          >
            Login
          </Button>
          {md2 ? (
            <>
              {showForgetButton && (
                <Button
                  style={{
                    marginLeft: "100px",
                    backgroundColor: "#203040",
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={handleForgetPassword}
                >
                  Forget Password ?
                </Button>
              )}
            </>
          ) : (
            <>
              {showForgetButton && (
                <Button
                  style={{
                    marginLeft: "20%",
                    backgroundColor: "#203040",
                    color: "white",
                  }}
                  className={classes.button}
                  onClick={handleForgetPassword}
                >
                  Forget Password ?
                </Button>
              )}
            </>
          )}
        </CardActions>
        <div>
          {loading && (
            <CircularProgress
              size="15px"
              color="inherit"
              className={classes.logging}
            />
          )}
        </div>
      </form>
    </CardContent>
  </Card>
  <Dialog
    PaperProps={{ sx: { width: "50%", height: "50%" } }}
    TransitionComponent={Transition}
    open={forgetOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {" "}
    <Box position="absolute" top={0} right={0}>
      <IconButton onClick={handleCloseForget}>
        <CloseIcon />
      </IconButton>
    </Box>
    <DialogContent divider style={{ marginTop: "10px" }}>
      <Collapse in={openAlert}>
        <Alert
          severity={openAlertCon}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {openAlerMess}
        </Alert>
      </Collapse>
      <DialogContentText>Enter Your Email</DialogContentText>
      <DialogContentText>
        <TextField
          fullWidth
          //	autoFocus
          //color='primary'
          className={classes.borderTextField}
          margin="normal"
          variant="outlined"
          placeholder="Email"
          value={emaileInput}
          onChange={(e) => {
            setEmailInput(e.target.value);
          }}
        />
        <div>
          {loading && (
            <CircularProgress
              size="15px"
              color="inherit"
              className={classes.logging}
            />
          )}
        </div>
        <Button
          onClick={() => {
            sendEmaile();
          }}
          style={{ backgroundColor: "#203040", color: "white" }}
        >
          Send
        </Button>
      </DialogContentText>
    </DialogContent>
  </Dialog>
</div>; */}
