import ProductsPage from "./products";

export const ProductPageConfig = {
  routes: [
    {
      path: "/products",
      exact: true,
      component: ProductsPage
    }
  ]
};
