import LoginPage from "./loginTwo";

export const LoginPageConfig = {
  routes: [
    {
      path: "/adminLogin",
      exact: true,
      component: LoginPage
    }
  ]
};
