import SalesProduct from "./ManageProducts";

export const SalesProductPageConfig = {
  routes: [
    {
      path: "/salesProduct",
      exact: true,
      component: SalesProduct
    }
  ]
};
