import SignUp from "./register";

export const RigisterConfig = {
  routes: [
    {
      path: "/signup",
      exact: true,
      component: SignUp
    }
  ]
};
