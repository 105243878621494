//categorie
import CategoriePage from "./category";

export const CategoriePageCOnfig = {
  routes: [
    {
      path: "/categorie",
      exact: true,
      component: CategoriePage
    }
  ]
};
