// import DashboardPage from "./dashbordOrginal";
import LoginPage from "../../pages/auth/login/loginTwo"
export const DashboardPageConfig = {
  routes: [
    {
      path: "/",
      exact: true,
      component:LoginPage,
    },
  ],
};
