//categorie
import CategoriePage from "./categorie";

export const SalesCategoriePageCOnfig = {
  routes: [
    {
      path: "/salesCategorie",
      exact: true,
      component: CategoriePage
    }
  ]
};
